/*==== Content Popup Form ====*/
.content-blocks.pop {
  left: 100%;
  opacity: 0.3;
  top: 0;
  transition: all 0.3s ease-out; }
  .content-blocks.pop.showx {
    left: 30%;
    opacity: 1; }
  .menu4 .content-blocks.pop.showx {
    left: 20%; }
  .content-blocks.pop .content {
    padding-top: 110px; }

.close-pop {
  background-color: #F1F3F6;
  cursor: pointer;
  line-height: 40px;
  height: 40px;
  position: fixed;
  text-align: center;
  width: 69%;
  right: 13px;
  top: -60px;
  transition: all 0.7s;
  z-index: 1; }

.d-block {
  display: block !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.project-description {
  margin: 30px 0; }

.project-head span {
  color: #333;
  font-weight: 400;
  margin-right: 5px; }

.project-media img {
  margin-bottom: 30px; }

.project-nav {
  margin-top: 50px; }
  .project-nav a {
    color: inherit;
    font-weight: 500; }
  .project-nav i {
    font-size: 24px; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.w-100 {
  width: 100% !important; }

/*==== Responsive ====*/
@media (max-width: 1280px) {
  .close-pop {
    right: 0;
    width: 70%; } }

@media (max-width: 800px) {
  .menu4 .content-blocks.pop {
    left: 100%; }
  .content-blocks.pop.showx {
    left: 0; }
  .close-pop {
    right: 0;
    width: 100%; } }

@media (max-width: 767px) {
  .content-blocks.pop.showx {
    height: 100vh;
    top: 0; } }
