/* ======= Timeline ======= */
.timeline {
  position: relative; }
  .timeline:before {
    background: #ddd;
    bottom: 0;
    content: "";
    left: 18px;
    position: absolute;
    top: 50px;
    width: 1px;
    height: 75%; }
  .timeline:after {
    font-family: 'Ionicons';
    top: 0;
    left: -5px;
    width: 50px;
    height: 50px;
    position: absolute;
    text-align: center;
    color: #ddd;
    font-size: 36px;
    line-height: 45px; }

.exp-holder {
  margin-top: 70px; }

.exp {
  padding: 20px 20px 0 80px;
  position: relative; }
  .exp:after {
    background: #FFF;
    border: 2px solid #ddd;
    border-radius: 50%;
    content: "";
    height: 20px;
    left: 9px;
    position: absolute;
    text-align: center;
    top: 24px;
    width: 20px; }

@media (max-width: 980px) {
  .timeline:before, .timeline:after {
    display: none; }
  .exp {
    padding-left: 0; }
    .exp-holder {
      margin-top: 0; }
    .exp:after {
      display: none; } }
