.post {
  background-color: #fff;
  margin: 25px 0; }
  .post:last-child {
    border-bottom: 0; }
  .post-thumbnail {
    text-align: center; }
  .post-title {
    text-align: center; }
  .post-title h2 {
    font-size: 28px;
    margin-top: 50px;
    margin-bottom: 20px; }
  .post-info {
    font-size: 14px; }
  .post-info:after {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    background: #333;
    margin: 20px auto; }
  .post-info span {
    padding: 0 2px; }
  .post-info .slash:before {
    content: "/"; }
  .post-body {
    padding: 20px 0;
    text-align: center; }
  .post-body p {
    padding-bottom: 20px; }
  .post-body .btn {
    margin-top: 0; }
