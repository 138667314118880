label {
  display: inline-block;
  margin-bottom: .5rem;
  touch-action: manipulation; }

.progress-bar-label {
  color: #333;
  font-weight: 500;
  margin-bottom: 10px; }
  .progress-bar-label span {
    font-weight: 300; }
