.exp .hgroup {
  margin-bottom: 5px; }

.exp .hgroup h4 span {
  color: #666; }

.exp .hgroup h5 {
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  color: #727272;
  padding-left: 60px;
  position: relative; }

.exp .hgroup h5:before {
  color: #ddd;
  content: "/";
  left: 0;
  left: 30px;
  position: absolute; }

.exp .hgroup h4,
.exp .hgroup h5 {
  display: table-cell;
  text-transform: none;
  margin-bottom: 10px; }

.exp .hgroup h5 span.current {
  font-style: italic; }

.row {
  margin-right: -15px;
  margin-left: -15px; }

@media (max-width: 767px) {
  .exp .hgroup h4,
  .exp .hgroup h5 {
    display: table; }
  .exp .hgroup h5 {
    padding-left: 0; }
  .exp .hgroup h5:before {
    display: none; } }
