/* ======= Slider ======= */
.preloader-left {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #333;
  z-index: 999;
  transition: .7s .3s transform ease-in-out; }

.slideOut-left {
  transform: translate3d(-100%, 0, 0);
  will-change: transform; }

.slideOut-right {
  transform: translate3d(100%, 0, 0);
  will-change: transform; }
