/* ======= Name Block ======= */
.name-block {
  position: absolute;
  width: 700px;
  height: 700px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: table;
  transition: all 0.5s;
  z-index: 1; }
  .dark .name-block {
    border: 1px solid rgba(51, 51, 51, 0.2); }
  .name-block.reverse {
    position: absolute;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    width: 30%;
    height: 100%;
    left: 0;
    top: 0;
    border: none;
    display: table; }

.menu2 .name-block {
  border: none;
  padding: 0 0 0 50px; }

.menu2 .name-block.reverse {
  width: 100%; }

.menu4 .name-block.reverse {
  width: 100%;
  padding-left: 50px; }

.name-block-container {
  display: table-cell;
  vertical-align: middle;
  padding-left: 240px;
  -ms-transform: rotate(34deg);
  -webkit-transform: rotate(34deg);
  transform: rotate(34deg); }
  .name-block-container.reverse {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    padding: 0 30px; }
  .name-block-container h1,
  .name-block-container h2 {
    /*text-transform: capitalize*/
    font-weight: 700;
    color: #FFF;
    margin-bottom: 10px;
    text-transform: uppercase; }
  .darkh2 .name-block-container {
    color: #333; }
  .name-block-container h2 {
    font-size: 26px;
    min-height: 26px; }
  .name-block-container h1 span {
    font-size: 26px;
    font-weight: 300;
    display: block;
    text-transform: capitalize; }

.social {
  position: absolute;
  bottom: 15%; }
  .social a {
    color: #FFF !important;
    display: inline-block;
    height: 35px;
    width: 35px;
    transition: all 0.5s; }
  .social li {
    display: inline-block;
    margin-right: 5px;
    text-align: center; }
    .social li i {
      line-height: 32px;
      font-size: 15px; }
  .social a:hover {
    opacity: 0.7; }
  .darka .social {
    border: 2px solid #333;
    color: #333; }

.typed-cursor {
  display: none; }

ul {
  margin-top: 0;
  margin-bottom: 1rem; }

/* ======= Responsive ======= */
@media (min-width: 1920px) {
  .name-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: 122px;
    top: 90px; }
    .name-block.reverse {
      width: 400px;
      left: 300px; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .name-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: -190px;
    top: 10px; } }

@media (min-width: 1025px) and (max-width: 1280px) {
  .name-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: -200px;
    top: -80px; } }

@media (min-width: 981px) and (max-width: 1024px) {
  .name-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: -190px;
    top: 10px; } }

@media (max-width: 1024px) {
  .name-block-container h1 {
    font-size: 40px;
    line-height: 1.1; }
  .name-block-container h1 span,
  .name-block-container h2 {
    font-size: 22px;
    min-height: 22px; }
  .menu2 .name-block-container h1 {
    font-size: 50px; }
  .menu2 .name-block-container h1 span,
  .menu2 .name-block-container h2 {
    font-size: 26px;
    min-height: 26px; }
  .menu2 .name-block {
    padding: 0 0 0 20px; } }

@media (min-width: 768px) and (max-width: 980px) {
  .name-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: -190px;
    top: 10px; } }

@media (max-width: 800px) and (orientation: landscape) {
  .name-block {
    position: relative;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    width: 100%;
    height: auto;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    display: table;
    left: 0;
    top: 0; }
  .name-block-container {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    padding: 0 0 0 0; }
  .social {
    position: relative;
    margin: 50px 0; } }

@media (max-width: 800px) {
  .name-block {
    padding-top: 30px;
    position: relative;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    width: 100%;
    height: auto;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    display: table;
    left: 0;
    top: 0; }
  .name-block-container {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    padding: 0 0 0 0; }
  .social {
    position: relative;
    margin: 50px 0; }
  .menu4 .social {
    bottom: 0; } }

@media (max-width: 767px) {
  .name-block.reverse {
    padding-left: 0; }
  .name-block-container h1 {
    font-size: 36px; }
  .name-block-container h2 {
    font-size: 22px;
    min-height: 22px; }
  .name-block-container h1 span {
    font-size: 22px; }
  .social {
    display: none; } }
