/*=== Hackathon or Awards ===*/
.awards {
  padding-top: 30px; }
  .awards img {
    opacity: 0.7; }
  .awards:hover img {
    opacity: 1; }

img {
  max-width: 100%;
  height: auto;
  transition: all 0.3s;
  vertical-align: middle;
  border-style: none; }

@media (max-width: 980px) {
  .awards img {
    opacity: 1; } }
