/*==== Contact Button... ====*/
.text-center {
  text-align: center !important; }

.btn-email:before {
  position: absolute;
  left: 0;
  top: 45px;
  content: '';
  border-top: 1px solid #F1F3F6;
  width: 100%; }
