/* ======= Resume Block ======= */
/* Round */
/* Menu Block */
/* Responsive Menu Block */
.resume-block {
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: table;
  transition: all 0.5s;
  z-index: 99;
  width: 430px;
  height: 300px; }
  .dark .resume-block {
    border: 1px solid rgba(51, 51, 51, 0.4); }
  .resume-block:hover {
    border: 15px solid rgba(255, 255, 255, 0.1); }
  .resume-block-container {
    display: table-cell;
    vertical-align: middle;
    padding: 150px 0 0 70px;
    -ms-transform: rotate(34deg);
    -webkit-transform: rotate(34deg);
    transform: rotate(34deg); }

/* ======= Responsive ======= */
@media (min-width: 1920px) {
  .resume-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: 702px;
    top: -60px; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .resume-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: 382px;
    top: -151px; } }

@media (min-width: 1025px) and (max-width: 1280px) {
  .resume-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: 419px;
    top: -160px;
    width: 350px;
    height: 300px; } }

@media (min-width: 981px) and (max-width: 1024px) {
  .resume-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: 382px;
    top: -150px; } }

@media (min-width: 768px) and (max-width: 980px) {
  .resume-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: 382px;
    top: -150px; } }

@media (max-width: 800px) and (orientation: landscape) {
  .resume-block {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    position: relative;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    .resume-block-container {
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      padding: 0 0 0 0; } }

@media (max-width: 800px) {
  .resume-block {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    position: relative;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 0 0 0; }
    .resume-block-container {
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      padding: 0 0 0 0; }
    .resume-block:hover {
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); } }
