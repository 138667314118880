/* ======= Portfolio Block ======= */
/* Round */
/* Menu Block */
/* Responsive Menu Block */
.portfolio-block {
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: table;
  transition: all 0.5s;
  z-index: 1;
  width: 430px;
  height: 400px; }
  .dark .portfolio-block {
    border: 1px solid rgba(51, 51, 51, 0.4); }
  .portfolio-block:hover {
    border: 15px solid rgba(255, 255, 255, 0.1); }
  .portfolio-block-container {
    display: table-cell;
    vertical-align: middle;
    -ms-transform: rotate(34deg);
    -webkit-transform: rotate(34deg);
    transform: rotate(34deg); }

/* ======= Responsive ======= */
@media (min-width: 1920px) {
  .portfolio-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: 897px;
    top: 179px; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .portfolio-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: 577px;
    top: 89px; } }

@media (min-width: 1025px) and (max-width: 1280px) {
  .portfolio-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: 586px;
    top: 88px;
    width: 350px;
    height: 300px; } }

@media (min-width: 981px) and (max-width: 1024px) {
  .portfolio-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: 577px;
    top: 89px; } }

@media (min-width: 768px) and (max-width: 980px) {
  .portfolio-block {
    -ms-transform: rotate(-34deg);
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    left: 577px;
    top: 89px; } }

@media (max-width: 800px) and (orientation: landscape) {
  .portfolio-block {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    position: relative;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 0 0 0; }
    .portfolio-block-container {
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      padding: 0 0 0 0; } }

@media (max-width: 800px) {
  .portfolio-block {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    position: relative;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    .portfolio-block-container {
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      padding: 0 0 0 0; }
    .portfolio-block:hover {
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); } }
