/* ======= Base ======= */
*, ::after, ::before {
  box-sizing: border-box; }

body {
  margin: 0;
  font: 400 14px 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  line-height: 30px;
  color: #727272;
  font-size: 16px;
  font-weight: 300;
  line-height: 2.2;
  overflow-x: hidden; }

a {
  transition: all 0.3s; }
  a:hover, a:focus {
    text-decoration: none;
    outline: none; }
  a[href^='tel:'] {
    color: inherit;
    text-decoration: none; }
  a[href^='mailto:'] {
    text-decoration: none; }
  a:not([href]):not([tabindex]) {
    color: #FFF;
    cursor: pointer;
    text-decoration: none; }
  a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }

img {
  max-width: 100%;
  height: auto;
  transition: all 0.3s; }

/* ======= Button ======= */
.btn {
  border: 2px solid transparent;
  border-radius: 0;
  padding: 12px 28px;
  margin: 20px 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  transition: all .3s;
  position: relative;
  color: #FFF;
  background-color: #333;
  z-index: 20;
  letter-spacing: 2px;
  overflow: hidden; }
  .btn:hover {
    color: #666;
    background-color: #FFF;
    border: 2px solid #666;
    letter-spacing: 3px; }
  .btn:focus {
    box-shadow: none;
    color: #FFF;
    outline: none; }
  .btn-download {
    border: none;
    font-size: 14px;
    padding: 16px 55px 16px 25px; }
  .btn-download:hover {
    border: none;
    color: #FFF;
    background-color: #333;
    letter-spacing: 2px; }
  .btn-download:before {
    position: absolute;
    content: '\f407';
    font-family: 'Ionicons';
    top: 12px;
    right: 16px;
    font-size: 24px; }
  .btn-download:hover:before {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  .btn-download:after {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    right: 0;
    top: 0;
    height: 100%;
    width: 0;
    z-index: -1;
    transition: all .3s; }
  .btn-download:hover:after {
    width: 50px;
    height: 70px;
    right: -5px;
    top: -5px;
    -ms-transform: rotate(15deg);
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  .btn.disabled {
    opacity: 1; }

/* ======= texts ======= */
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-weight: 500;
  color: #333;
  letter-spacing: 1px; }

h1 {
  font-size: 50px;
  line-height: 60px;
  margin-top: 0;
  margin-bottom: 0; }

h2 {
  font-size: 22px;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: 0; }

h3 {
  font-size: 20px;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500; }

h4 {
  font-size: 16px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0; }

h5 {
  font-size: 15px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 0; }

h6 {
  font-size: 14px;
  line-height: 32px;
  font-weight: 500; }

p {
  margin: 0;
  margin-bottom: 30px; }

ul {
  list-style: none;
  padding-left: 0; }

strong,
small {
  color: #333; }

/* ======= Multi-components ======= */
.hidex {
  opacity: 0;
  visibility: hidden;
  z-index: -1; }

.lowercase {
  text-transform: lowercase; }

/* ======= Modal backdrop ======= */
.modal-backdrop {
  width: 0;
  height: 0; }
