/* ======= Portfolio Content ======= */
.portfolio-container {
  overflow: hidden;
  position: relative; }

.classic .portfolio-content {
  background: #F1F3F6;
  position: relative;
  top: 0;
  transform: translateY(0); }

.classic .portfolio-content,
.classic .portfolio-content h2,
.classic .portfolio-content p {
  opacity: 1;
  text-align: left; }

.classic .portfolio-content h2 {
  color: #333;
  padding: 20px 30px 0;
  margin-top: 0; }

.classic .portfolio-column:hover .portfolio-content h2 {
  margin-top: 0; }

.classic .portfolio-content p {
  color: #666;
  margin-top: 0; }

.portfolio-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #181818;
  top: 0;
  z-index: 200;
  opacity: 0; }

.portfolio-column:hover .portfolio-overlay {
  opacity: 0.8; }

.filters {
  padding-bottom: 30px; }
  .filters span {
    display: inline-block;
    margin-right: 15px; }
  .filters ul {
    display: inline-block;
    margin: 20px 0px; }
  .filters ul li {
    font-weight: 500;
    display: inline-block;
    margin-left: 15px;
    cursor: pointer;
    padding: 2px 10px;
    transition: all 0.3s ease 0s; }
  .filters ul li:first-child {
    margin-left: 0px; }
  .filters ul li.active,
  .filters ul li:hover {
    color: #333333; }

ul {
  padding-left: 0px;
  list-style: none; }
