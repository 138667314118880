/* ======= inline-header ======= */
.inline-header {
  background: #F1F3F6;
  position: fixed;
  height: 40px;
  width: 69%;
  right: 13px;
  top: -50px;
  transition: all 0.5s;
  z-index: 1; }
  .inline-header i {
    cursor: pointer; }
  .inline-header.showx {
    top: 0; }

.status {
  color: #333;
  line-height: 38px;
  padding-left: 45px;
  margin: 1.5em; }
  .status:before {
    position: absolute;
    top: 17px;
    left: 25px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #4dd133;
    content: ''; }
  .status:after {
    position: absolute;
    top: 13px;
    left: 21px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #4dd133;
    content: '';
    opacity: 0.3; }

#close i {
  font-size: 30px;
  vertical-align: middle; }

.inline-header-menu {
  float: right;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 20px; }
  .inline-header-menu li {
    display: inline-block;
    cursor: pointer;
    font-weight: 600;
    line-height: 40px;
    margin-top: -5px;
    padding: 0 15px;
    color: #666; }
  .inline-header-menu li.active,
  .inline-header-menu li:hover {
    color: #333; }
  .inline-header-menu li.active {
    cursor: text; }
  .inline-header-menu-container.style2 {
    background: transparent; }
  .style2 .inline-header-menu li {
    color: #FFF;
    font-weight: 400;
    line-height: 70px; }

.style2.dark {
  background-color: #F1F3F6; }
  .style2.dark .inline-header-menu li {
    color: #666;
    font-size: 14px;
    line-height: 40px; }

.inline-header.style3 {
  background: #FFF;
  border-bottom: 1px solid #eee;
  height: 40px; }

.sidebar-menu {
  background: #111;
  height: 100%;
  left: 0;
  position: fixed;
  width: 20%; }
  .sidebar-menu.hidex {
    opacity: 1;
    visibility: visible;
    z-index: 1; }

.menu4 .inline-header-menu {
  margin-right: 0;
  position: fixed;
  top: 50%;
  transform: translateY(-50%); }
  .menu4 .inline-header-menu li {
    color: #787878;
    display: block;
    font-size: 20px;
    font-weight: 300;
    line-height: 60px;
    padding: 0 0 0 30px; }
  .menu4 .inline-header-menu li.active {
    color: #FFF; }

.close-pop {
  background-color: #F1F3F6;
  cursor: pointer;
  line-height: 40px;
  height: 40px;
  position: fixed;
  text-align: center;
  width: 69%;
  right: 13px;
  top: -60px;
  transition: all 0.7s;
  z-index: 1; }
  .menu4 .close-pop {
    width: 79%; }
  .close-pop:hover {
    opacity: 0.7; }
  .content-blocks.pop.showx .close-pop {
    top: 0; }
  .menu4 .close-pop {
    width: 79%; }
  .close-pop:hover {
    opacity: 0.7; }
  .content-blocks.pop.showx .close-pop .close-pop {
    top: 0; }

@media (min-width: 1920px) {
  .inline-header,
  .close-pop {
    width: 1024px;
    left: auto;
    right: 0; } }

@media (max-width: 1280px) {
  .inline-header,
  .close-pop {
    right: 0;
    width: 70%; } }

@media (max-width: 1024px) {
  .inline-header {
    font-size: 14px;
    right: 10px;
    width: 69%; }
    .inline-header-menu li {
      padding: 0 10px; } }

@media (max-width: 980px) {
  .inline-header i {
    margin: .2em; }
  .inline-header-menu {
    margin-top: 5px; }
  .status {
    display: none; } }

@media (max-width: 800px) and (orientation: landscape) {
  .inline-header {
    width: 100%;
    left: 0; } }

@media (max-width: 800px) {
  .inline-header {
    width: 100%;
    left: 0; } }

@media (max-width: 320px) {
  .inline-header-menu {
    margin-right: 0; }
    .inline-header-menu li {
      padding: 0 8px; } }
