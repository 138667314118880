.contact-content {
  display: table;
  width: 100%;
  margin-top: 30px; }

.contact-details, .contact-icon {
  display: table-cell; }

.contact-icon {
  width: 60px;
  vertical-align: top;
  font-size: 30px;
  text-align: center;
  color: #666; }
