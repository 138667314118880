/* ======= Menu Blocks ======= */
.menu-blocks {
  transition: all 0.5s; }
  .menu-blocks.hidex {
    z-index: -999; }
  .menu-blocks.showx {
    z-index: 999; }

.menu-block {
  cursor: pointer; }
  .menu-block:hover {
    background: rgba(0, 0, 0, 0.1); }

.menu-item {
  cursor: pointer; }

h2.menu-item {
  position: relative;
  color: #FFF;
  margin: 50px;
  font-size: 28px;
  font-weight: 600;
  transition: all 1s ease-out; }

.dark h2.menu-item {
  color: #333; }

h2.menu-item:before {
  position: absolute;
  top: -40px;
  left: -20px;
  font-size: 14px;
  transition: all 0.5s ease; }

h2.menu-item:after {
  position: absolute;
  content: '';
  top: -10px;
  left: -20px;
  width: 70px;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
  transition: all 0.5s ease; }

h2.resume.menu-item:before {
  content: '01'; }

h2.portfolio.menu-item:before {
  content: '02'; }

h2.blog.menu-item:before {
  content: '03'; }

h2.contact.menu-item:before {
  content: '04'; }

.menu-block:hover h2.menu-item:before {
  top: 0px;
  left: -40px; }

.menu-block:hover h2.menu-item:after {
  top: 12px;
  left: -40px;
  opacity: 0;
  visibility: hidden; }

h2.dark.menu-item:after {
  background: #333; }

.menu-item:after h2.menu-block:hover {
  top: 12px;
  left: -40px; }

/* ======= Responsive ======= */
@media (max-width: 800px) and (orientation: landscape) {
  .menu-block:hover {
    background: rgba(0, 0, 0, 0); }
    .menu-block:hover h2.menu-item:before {
      top: -40px;
      left: -20px; }
    .menu-block:hover h2.menu-item:after {
      top: 15px;
      left: -20px; }
  h2.menu-item {
    line-height: 3em;
    margin-bottom: 0;
    display: block; }
    h2.menu-item:after {
      position: absolute;
      content: '';
      top: 15px;
      left: -20px;
      width: 70px;
      height: 1px;
      background: #FFF;
      transition: all 0.5s ease; } }

@media (max-width: 800px) {
  .menu4 .content-blocks {
    width: 100%;
    left: 0; }
  .menu4 .content-blocks.pop {
    left: 100%; }
  .menu4 .content-blocks.pop.showx {
    left: 0; }
  .menu-block:hover {
    background: rgba(0, 0, 0, 0); }
    .menu-block:hover h2.menu-item:before {
      top: -40px;
      left: -20px; }
    .menu-block:hover h2.menu-item:after {
      top: 15px;
      left: -20px; }
  h2.menu-item {
    line-height: 3em;
    margin-bottom: 0;
    display: block; }
  h2.menu-item:after {
    position: absolute;
    content: '';
    top: 15px;
    left: -20px;
    width: 70px;
    height: 1px;
    background: #FFF;
    transition: all 0.5s ease; } }

@media (max-width: 767px) {
  .menu-block:hover h2.menu-item:before {
    top: -20px;
    left: -20px; }
  h2.menu-item {
    font-size: 20px;
    line-height: 2em;
    margin: 25px 0 25px 40px; }
  h2.menu-item:before {
    position: absolute;
    top: -20px;
    left: -20px;
    font-size: 14px; }
  h2.menu-item:after {
    display: none; } }
