/* ======= Resume Contents ======= */
.link {
  font-style: italic;
  font-weight: bold;
  padding: 0 3px; }

.sign {
  margin: 30px 0; }

.service {
  text-align: center;
  padding-top: 25px; }
  .service-icon {
    font-size: 30px;
    margin-bottom: 10px;
    color: #666; }
  .service h4 {
    margin: 10px 0;
    font-size: 14px; }
