.pagination {
  display: inline-flex !important;
  margin-bottom: 0; }

.page-link,
.page-item.disabled .page-link {
  background-color: #f1f3f6 !important;
  border-color: #f1f3f6 !important;
  color: #868e96 !important; }

.page-link {
  color: #727272 !important; }

.page-item.active .page-link {
  color: #ffffff !important;
  background-color: #666 !important;
  border-color: #666 !important; }

.page-link:focus .page-link:hover {
  color: #333;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #ddd; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0 0 0 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%); }
