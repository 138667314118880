/* ======= Portfolio item ======= */
a {
  transition: all 0.3s ease 0s;
  text-decoration: none; }

a [role="button"],
a,
a area,
a button,
a input,
a label,
a select,
a summary,
a textarea {
  touch-action: manipulation; }

.portfolio-column {
  overflow: hidden;
  position: relative;
  width: 100%;
  transition: all 1s; }
  .portfolio-column:hover {
    -webkit-box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3); }
  .portfolio-column:hover img {
    transform: scale(1.1); }
  .portfolio-column:hover .portfolio-content {
    opacity: 1;
    cursor: pointer; }
  .portfolio-column:hover .portfolio-content h2 {
    margin-top: 20px;
    opacity: 1; }
  .portfolio-column:hover .portfolio-content p {
    margin-top: 0;
    opacity: 1; }

.portfolio-content {
  position: absolute;
  z-index: 201;
  top: 50%;
  width: 100%;
  opacity: 0;
  transform: translateY(-50%); }
  .portfolio-content h2 {
    color: #FFF;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: 30px;
    text-align: center;
    padding: 0 30px;
    opacity: 0;
    text-transform: uppercase;
    transition: all 0.6s; }
  .portfolio-content hr {
    width: 40px;
    height: 1px;
    border: 0;
    background-color: #F1F3F6;
    margin: 20px 0 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%); }
  .portfolio-content p {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: -30px;
    text-align: center;
    padding: 10px 30px;
    opacity: 0;
    transition: all 0.9s; }

.portfolio-item {
  padding-bottom: 30px; }
  .portfolio-item.no-gutter {
    padding: 0 0;
    padding-bottom: 0; }

.portfolio-item img {
  width: 100%;
  transition: all 5s; }
