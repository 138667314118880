/* ======= Profile Contents ======= */
.subheading {
  font-size: 30px;
  font-weight: 300;
  line-height: 2em;
  margin-bottom: 30px;
  text-transform: none; }

.info-list {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding-bottom: 20px;
  padding-top: 20px;
  margin: 20px 0;
  overflow: auto; }
  .info-list span {
    color: #333;
    font-weight: 400;
    margin-right: 5px; }
  .info-list div {
    padding-left: 0;
    padding-right: 0; }
