/*=== Contact === */
input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  touch-action: manipulation; }

small {
  font-size: 80%;
  font-weight: 400;
  color: #333; }

.alert {
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem; }
  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }

.fade {
  opacity: 0;
  transition: opacity .15s linear; }

.error-form {
  float: right;
  margin: -24px 2px 0 0;
  position: relative;
  top: 0; }
  .error-form i:before {
    color: #C33;
    font-size: 100%;
    vertical-align: top;
    margin-right: 10px; }

.alert-success {
  text-align: left; }

.form-control {
  display: block;
  width: 100%;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s; }
  .form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none; }

#contact_form .form-control {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #727272;
  border-radius: 0;
  width: 100%;
  font-size: 14px;
  transition: all .3s; }
  #contact_form .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #666; }

#contact_form textarea {
  resize: none; }

#map {
  height: 400px;
  margin-top: 50px;
  width: 100%; }
